import Leaflet from 'leaflet'
import 'leaflet/dist/leaflet.css';
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const DefaultIcon = Leaflet.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
    shadowUrl: iconShadow
});

const StartIcon = Leaflet.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: process.env.PUBLIC_URL+'/icons/start.png',
    shadowUrl: iconShadow
})

const FinishIcon = Leaflet.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: process.env.PUBLIC_URL+'/icons/finish.png',
    shadowUrl: iconShadow
})

const TurnIcon = Leaflet.icon({
    iconSize: [25, 25],
    iconAnchor: [10, 25],
    popupAnchor: [2, -40],
    iconUrl: process.env.PUBLIC_URL+'/icons/turn.png',
    shadowUrl: iconShadow
})

const FunnelIcon = Leaflet.icon({
    iconSize: [25, 25],
    iconAnchor: [10, 25],
    popupAnchor: [2, -40],
    iconUrl: process.env.PUBLIC_URL+'/icons/narrow.png',
    shadowUrl: iconShadow
})
export { DefaultIcon, StartIcon, FinishIcon, TurnIcon, FunnelIcon };