import haversine from 'haversine-distance'

export const addCumulativeDistance = (points) =>{
    var points_cum_distance = []
    var prev_lat = null
    var prev_lon = null
    var cum_distance = 0

    for(var point of points){
        var latitude = point[0]
        var longitude = point[1]
        var elevation = point[2]

        if(prev_lat && prev_lon)
            cum_distance+=haversine({lat: prev_lat, lng: prev_lon}, {lat: latitude, lng: longitude})
        
        points_cum_distance.push([latitude, longitude, elevation, cum_distance])
        
        prev_lat = latitude
        prev_lon = longitude
    }

    return points_cum_distance
}

export const processPointsToElevationProfile = (points, every_x_m=50) =>{
    var metadata = []
    var elevation_profile = []
    var prev_lat = null
    var prev_lon = null
    var cum_distance = 0
    var previous_bin = 0

    for(var point of points){
        var latitude = point[0]
        var longitude = point[1]
        var elevation = point[2]

        if(prev_lat!==null){
            cum_distance+=haversine({lat: prev_lat, lng: prev_lon}, {lat: latitude, lng: longitude})
        }

        prev_lat = latitude
        prev_lon = longitude

        if(Math.floor(cum_distance/every_x_m)>previous_bin){
            elevation_profile.push({
                x: Math.round(cum_distance),
                y: elevation
            })
            metadata.push([latitude, longitude])
            previous_bin = Math.floor(cum_distance/every_x_m)
        }
    }

    return [elevation_profile, metadata]
}

export const findIndex = (point, points)=>{
    for(var i=0; i<points.length; i++){
        var pt = points[i]
        if(pt[0]===point[0] && pt[1]===point[1]){
            return i
        }
    }
    return -1
}

export const findClosestIndex = (point, points)=>{
    var closest = -1;
    var min_dist = 999999999;
    for(var i=points.length-1; i>0; i--){
        var pt = points[i]
        var dist = haversine({lat: point[0], lng: point[1]}, {lat: pt[0], lng: pt[1]})
        if(dist<min_dist){
            closest = i;
            min_dist = dist;
        } 
    }
    return closest
}