import React, { useState, useEffect } from 'react'
import MaterialTable from "material-table";
import {useHistory} from 'react-router-dom';

import {loadCourses} from '../api/course'
import {formatCourse} from '../util/formatting'

export default function CoursesTable(props){
    const history = useHistory()
    var [courses, setCourses] = useState([])

    useEffect(()=>{
        loadCourses().then((response)=>{
            var new_courses = []
            for(var course of response){
                if('attached_racedate' in course){
                    var formatted_course = formatCourse(course)
                    new_courses = [...new_courses, formatted_course]
                }
            }
            setCourses(new_courses)
        })
    },[])

    let columns = [
            { title: 'Race name', field: 'attached_race'},
            { title: 'Stage index', field: 'attached_stage_index'},
            { title: 'Race date', field: 'formatted_racedate', defaultSort: 'asc', customSort: (a,b) => new Date(a.attached_racedate)-new Date(b.attached_racedate)},
            { title: 'Distance (km)', field: 'formatted_distance'},
            { title: 'Elevation (m)', field: 'formatted_elevation'},
            { title: 'Bunch sprint?', field: 'is_bunchsprint'},
            { title: 'Hardness index', field: 'stage_hardness'},
            { title: '# finish passages', field: 'amount_finish_passages'}
    ];

    const loadCourse = (evt, rowData)=>{
        var url = `/course/${rowData._id["$oid"]}`
        history.push(url)
    }

    return (
        <div className='coursesTable'>
            <MaterialTable
                title='Courses overview'
                columns={columns}
                data={courses}
                onRowClick={loadCourse}
                options={{filtering: true, pagination: true, pageSize: 20, sorting: true}} 
            />
        </div>
        
    )
}