export const formatCourse = (course)=>{
    var course_cpy = {...course};
    course_cpy['formatted_distance'] = Math.round(course['course_length_meters']/1000)
    course_cpy['stage_hardness'] = Math.round(course['stage_hardness'])
    course_cpy['formatted_elevation'] = Math.round(course['course_uphill_meters'])
    course_cpy['attached_racedate'] = course['attached_racedate']['$date']
    course_cpy['formatted_racedate'] = new Date(course['attached_racedate']['$date']).toDateString()
    return course_cpy;
}

export const customTooltips = function(tooltipModel) {
    // Tooltip Element
    var tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '';
        document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }

    function getBody(bodyItem) {
        return bodyItem.lines;
    }

    // Set Text
    if (tooltipModel.body) {
        var bodyLines = tooltipModel.body.map(getBody);

        var innerHtml = '';
        bodyLines.forEach(function(body, i) {
            var text = body[0]
            var distance = text.split(',')[0].substring(1),
            elevation = parseFloat(text.split(',')[1])
            innerHtml += `<p><i class="fa fa-mountain"></i>${Math.round(elevation)}m</p>`
            innerHtml += `<p><i class="fa fa-road"></i>${Math.round(distance/10)/100}km</p>`
        });
        innerHtml += '</tbody>';
        tooltipEl.innerHTML = innerHtml;
    }

    // `this` will be the overall tooltip
    var position = this._chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + 0 + 'px';
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.padding = 1 + 'px ' + 1 + 'px';
    tooltipEl.style.pointerEvents = 'none';
};