import React from 'react';
import { Range, getTrackBackground } from 'react-range';

export const KMSlider = (props)=>{
    const STEP = 1;
    const MIN = 0;
    const MAX = props.distance;
    const values = props.values;
    const rtl = false;

    return (
                <div
                    style={{
                        marginLeft: '15px',
                        marginRight: '15px',
                        marginTop: '15px',
                        marginBottom: '15px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        textAlign: 'center'
                    }}
                    >
                    <Range
                        draggableTrack
                        values={values}
                        step={STEP}
                        min={MIN}
                        max={MAX}
                        rtl={rtl}
                        onChange={(values) => {
                            props.changeCallback(values);
                        }}
                        renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                            ...props.style,
                            height: '36px',
                            display: 'flex',
                            width: '100%'
                            }}
                        >
                            <div
                            ref={props.ref}
                            style={{
                                height: '5px',
                                width: '100%',
                                borderRadius: '4px',
                                background: getTrackBackground({
                                values,
                                colors: ['#ccc', '#548BF4', '#ccc'],
                                min: MIN,
                                max: MAX,
                                rtl
                                }),
                                alignSelf: 'center'
                            }}
                            >
                            {children}
                            </div>
                        </div>
                        )}
                        renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                            ...props.style,
                            height: '42px',
                            width: '42px',
                            borderRadius: '4px',
                            backgroundColor: '#FFF',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0px 2px 6px #AAA'
                            }}
                        >
                            <div
                            style={{
                                height: '16px',
                                width: '5px',
                                backgroundColor: isDragged ? '#548BF4' : '#CCC'
                            }}
                            />
                        </div>
                        )}
                    />
                    <output style={{ marginTop: '30px' }} id="output">
                       {`km ${(Math.round(values[0]/10)/100).toFixed(2)} - km ${(Math.round(values[1]/10)/100).toFixed(2)}`}
                    </output>
                    </div>
    )
}