import React, { useEffect, useState } from "react";

import {loadCourseTurns} from '../api/course'
import {addCumulativeDistance} from '../util/geo_processing'

import {KMSlider} from './km_slider'
import {Dropdown, DropdownButton} from 'react-bootstrap'

export default function CourseTurns(props){
    var id = props.id;
    var points = props.points;
    var details = props.details;

    var [turns, setTurns] = useState([])
    var [distanceInterval, setDistanceInterval] = useState([0, details.course_length_meters])
    var [turnTypeFilter, setTurnTypeFilter] = useState("FAST")

    var [processedPoints, setProcessedPoints] = useState([])

    useEffect(()=>{
        loadCourseTurns(id).then((data)=>{
            setTurns(data)
        })
    },[id, points])

    useEffect(()=>{
        if(points.length>0){
            var processed_points = addCumulativeDistance(points)
            console.log(processed_points);
            setProcessedPoints(processed_points)
        }
    }, [points])

    const zoomLevelChanged = (values)=>{
        setDistanceInterval(values)
    }

    return (
        <div>
            <div className='turn-options'>
                <button className='btn btn-secondary'onClick={()=>{setDistanceInterval([details.course_length_meters-5000, details.course_length_meters])}}>Last 5kms</button>
                <button className='btn btn-secondary'onClick={()=>{setDistanceInterval([0, details.course_length_meters])}}>Full race</button>
                <DropdownButton id="turnTypeDdn" variant='secondary' title={`${turnTypeFilter?turnTypeFilter:"ALL"} turns`}>
                    <Dropdown.Item onClick={()=>setTurnTypeFilter(null)}>All</Dropdown.Item>
                    <Dropdown.Item onClick={()=>setTurnTypeFilter("FAST")}>Fast</Dropdown.Item>
                    <Dropdown.Item onClick={()=>setTurnTypeFilter("SLOW")}>Slow</Dropdown.Item>
                </DropdownButton>
            </div>  
            <KMSlider distance={details.course_length_meters} values={distanceInterval} changeCallback={zoomLevelChanged} style={{width: '100%'}}/>
            <div className='course-turns'>
                    {
                    processedPoints.length>0&&turns.length>0?turns.map(turn=>{
                        if((!turnTypeFilter || (turnTypeFilter && turn.turn_type === turnTypeFilter)) && turn.turn_angle && processedPoints[turn.point_index][3]>=distanceInterval[0] && processedPoints[turn.point_index][3]<=distanceInterval[1]){
                            var rotationAngle = turn.turn_direction==='LEFT'?-turn.turn_angle:turn.turn_angle
                            return (
                                <div key={`turn-${turn.point_index}`} className='turn' 
                                    onMouseEnter={evt=>{props.setTurnLatLon(turn.point_index)}} 
                                    onMouseLeave={evt=>{props.setTurnLatLon(null)}}                    
                                >
                                    <div className='turn-icon'>
                                        <i className="far fa-arrow-alt-circle-up" style={{transform: `rotateZ(${rotationAngle}deg)`}}></i>
                                    </div>
                                    <div className='turn-distance-done'>
                                        <p>km</p>
                                        <p>{Math.round(processedPoints[turn.point_index][3]/10)/100}</p>
                                    </div>
                                </div>
                            )
                        }
                        else{
                            return ("")
                        }
                    }):""
                    }
            </div>
        </div>
    )
}