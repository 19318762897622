import React from 'react'
import { NavLink } from 'react-router-dom';
import {Navbar, Nav} from 'react-bootstrap';

export default function Header(){
    return (
        <header>
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                <Navbar.Brand>
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/UCI_Logo_2015.svg/800px-UCI_Logo_2015.svg.png"
                        width="auto"
                        height="30"
                        className="d-inline-block align-top"
                        alt=""
                    />{' '}
                    Course inspector
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <li className='nav-item'><NavLink className='nav-link' to="/">Overview</NavLink></li>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    )
}