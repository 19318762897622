import React, { useEffect, useState} from "react"
import { Chart, Scatter } from "react-chartjs-2";

var dataSetIcons = ['<i class="fa fa-solid fa-circle-notch"></i>', '<i class="fa-solid fa-pencil"></i>', '<i class="fa-solid fa-gauge-simple-high"></i>', '<i class="fa-solid fa-down-long"></i>', '<i class="fa-solid fa-arrow-up-long"></i>', '<i class="fa-solid fa-square-plus"></i>']

//TODO
// - set fixed axis
// - draw hlines
// - custom label when hovering
export default function PenaltySegment(props){
    let penaltySegments = props.penaltySegments;
    let distances = props.distances;
    let [roundaboutPoints, setRoundaboutPoints] = useState([])
    let [totalPenaltyPoints, setTotalPenaltyPoints] = useState([])
    let [elevationRewardPoints, setElevationRewardPoints] = useState([])
    let [elevationPenaltyPoints, setElevationPenaltyPoints] = useState([])
    let [changesPoints, setChangesPoints] = useState([])
    let [maxspeedPoints, setMaxspeedPoints] = useState([])
    // Will hold the reference to the chart.js linechart instance
    var [lineChartInst, setLineChartInst] = useState(null);
    var [segmentSet, setSegmentSet] = useState(null);

    useEffect(()=>{
        if(distances && distances.length>0){
            var roundaboutScore = []
            penaltySegments.forEach(element => {
                roundaboutScore.push({x: distances[element['segment_end_index']], y: element['roundabout_penalty']})
            });
            setRoundaboutPoints(roundaboutScore);
            var totalScore = []
            penaltySegments.forEach(element => {
                totalScore.push({x: distances[element['segment_end_index']], y: element['total_penalty']})
            });
            setTotalPenaltyPoints(totalScore)
            var changeScore = []
            penaltySegments.forEach(element => {
                changeScore.push({x: distances[element['segment_end_index']], y: element['waychanges_penalty']})
            });
            setChangesPoints(changeScore)
            var maxSpeedScore = []
            penaltySegments.forEach(element => {
                maxSpeedScore.push({x: distances[element['segment_end_index']], y: element['maxspeed_penalty']})
            });
            setMaxspeedPoints(maxSpeedScore)
            var elevationRewardScore = []
            penaltySegments.forEach(element => {
                if('elevation_reward' in element)
                    elevationRewardScore.push({x: distances[element['segment_end_index']], y: element['elevation_reward']})
                else
                    elevationRewardScore.push({x: distances[element['segment_end_index']], y: 0})
            });
            setElevationRewardPoints(elevationRewardScore)
            var elevationPenaltyScore = []
            penaltySegments.forEach(element => {
                if('elevation_reward' in element)
                    elevationPenaltyScore.push({x: distances[element['segment_end_index']], y: element['elevation_penalty']})
                else
                    elevationPenaltyScore.push({x: distances[element['segment_end_index']], y: 0})
            });
            setElevationPenaltyPoints(elevationPenaltyScore)

        }
    },[penaltySegments, distances])


    if(penaltySegments.length>0){
        return (
            <div className='charts'>
                {segmentSet?<div className='btn btn-danger btn-chart' onClick={()=>{props.unsetLineSegment();setSegmentSet(null);}}>Reset</div>:""}
                <Scatter ref={reference => setLineChartInst(reference)} options={
                    {
                        elements: {line:{tension: 0}},
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: "Penalty points",
                                },
                                ticks: {
                                    stepSize: 5,
                                    min: 0
                                }
                            }],
                            xAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: "Elapsed distance"
                                },
                                ticks: {
                                    min: 0,
                                    max: distances[distances.length-1],
                                    callback: function(label, index, labels){
                                        return Math.round(label/1000)+"km"
                                    }
                                },
                            }],
                        },
                        title: {
                                display: true,
                                text: 'OSM Penalties Along course'
                        },
                        horizontalLine: [{
                            y: 15,
                            style: "rgba(255, 0, 0, .4)",
                            text: "threshold"
                        }] ,
                        hover:{
                            mode: 'dataset'
                        },
                        onHover: e => {
                            // When a user hovers over the chart area this function will lookup the elevation at the x location of the cursor
                            // --> we will get the item index and look up its coordinates in the points array, so we can set the location
                            //      marker on the map (managed by using the setLatLon prop).
                            const item = lineChartInst.chartInstance.chart.getElementsAtEventForMode(e, 'index', { intersect: true}, false)
                            if(item.length) {
                                setSegmentSet(item[0]._index)
                                props.setLineSegment(item[0]._index)
                            }
                        },
                        tooltips: {
                            // We want a tooltip whenever the user hovers over the chart area (it will lookup the element at index x)
                            mode: 'index',
                            intersect: false, // Here we explicitly mention that the user should not necessarily intersect with the line whilst hovering
                            enabled: false,
                            custom: customPenaltyTooltips
                        },
                    }} data={{datasets: [
                    {
                        label: "Roundabout Penalty",
                        data: roundaboutPoints,
                        borderColor: 'green',
                        showLine: true,
                        hidden: true,
                    },
                    {
                        label: "Changes Penalty",
                        data: changesPoints,
                        borderColor: 'orange',
                        showLine: true,
                        hidden: true
                    },
                    {
                        label: "Maxspeed Penalty",
                        data: maxspeedPoints,
                        borderColor: 'purple',
                        showLine: true,
                        hidden: true
                    },
                    {
                        label: "Elevation Penalty",
                        data: elevationPenaltyPoints,
                        borderColor: 'DarkKhaki',
                        showLine: true,
                        hidden: true
                    },
                    {
                        label: "Elevation Reward",
                        data: elevationRewardPoints,
                        borderColor: 'LightSalmon',
                        showLine: true,
                        hidden: true
                    },
                    {
                        label: "Total Penalty",
                        data: totalPenaltyPoints,
                        borderColor: 'red',
                        showLine: true,
                        hidden: false
                    },
                    ]}}/>
            </div>
        )

    }
    else{
        return <div>This analysis has not yet been performed. Contact side administrator to request.</div>
    }
}

export const customPenaltyTooltips = function(tooltipModel) {
    // Tooltip Element
    var tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '';
        document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }

    // Set Text
    if (tooltipModel.body) {
        var innerHtml = '';
        tooltipModel.dataPoints.forEach(function(body, i) {
            var x = body.xLabel
            var y = body.yLabel

            if(innerHtml.length===0)
                innerHtml += `<p><i class="fa fa-road"></i> ${Math.round(x/10)/100}km</p>`
            innerHtml += `${dataSetIcons[body.datasetIndex]} ${Math.round(y)}</p>`
        });
        innerHtml += '</tbody>';
        tooltipEl.innerHTML = innerHtml;
    }

     // `this` will be the overall tooltip
     var position = this._chart.canvas.getBoundingClientRect();

     // Display, position, and set styles for font
     tooltipEl.style.opacity = 1;
     tooltipEl.style.position = 'absolute';
     tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
     tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
     tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
     tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
     tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
     tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
     tooltipEl.style.pointerEvents = 'none';

};

var horizonalLinePlugin = {
    afterDraw: function(chartInstance) {
      var yScale = chartInstance.scales["y-axis-1"];
      var xScale = chartInstance.scales["x-axis-1"];
      var canvas = chartInstance.chart;
      var ctx = canvas.ctx;
      var index;
      var line;
      var style;
      var yValue;
      if (chartInstance.options.horizontalLine) {
        for (index = 0; index < chartInstance.options.horizontalLine.length; index++) {
          line = chartInstance.options.horizontalLine[index];
          if (!line.style) {
            style = "rgba(169,169,169, .6)";
          } else {
            style = line.style;
          }
          if (line.y) {
            yValue = yScale.getPixelForValue(line.y);
          } else {
            yValue = 0;
          }
          ctx.lineWidth = 3;
          if (yValue) {
            ctx.beginPath();
            ctx.moveTo(xScale.getPixelForValue(0), yValue);
            ctx.lineTo(xScale._endPixel, yValue);
            ctx.strokeStyle = style;
            ctx.stroke();
          }
          if (line.text) {
            ctx.fillStyle = style;
            ctx.fillText(line.text, xScale.getPixelForValue(0)+5, yValue - ctx.lineWidth-5);
          }
        }
        return;
      };
    }
  };

  Chart.pluginService.register(horizonalLinePlugin);