import React from "react";

import {AiOutlineRise, AiFillFlag} from 'react-icons/ai'
import {GiPathDistance, GiCycling} from 'react-icons/gi'
import {TiArrowLoop} from 'react-icons/ti'
import {ImMeter2} from 'react-icons/im'
import {FaTachometerAlt, FaSignature} from 'react-icons/fa'

import {MdDateRange, MdClass} from 'react-icons/md'
import {FcOk, FcCancel} from 'react-icons/fc'
import {ProgressBar} from 'react-bootstrap'

import Gauge from 'react-svg-gauge';

export default function CourseDetails(props){
    var details = props.details;
    var straightness = props.finishlineStraightness;
    var thresholds = {
        '1000m_ratio': [50,35],
        '500m_ratio': [70,50]
    }
    var labels = {
        '1000m_ratio': "Of the last kilometer",
        '500m_ratio': "Of the last 500 meters"
    }
    const straightnessBar = (meters) =>{
        if(straightness && ['500m_ratio','1000m_ratio'].includes(meters)){
            var straightness_value = Math.round(straightness[meters]*100)
            var color = straightness_value>thresholds[meters][0]?"success":straightness_value<thresholds[meters][1]?"danger":"warning"
            return (
            <div className='straightness-ratio'>
                {labels[meters]}
                <ProgressBar variant={color} now={straightness_value} label={`${straightness_value}%`}/>
            </div>)
        }
        else{
            return (<div className='site-loader'></div>)
        }
    }
    return (
        <div>
        <div className='course-details'>
            <span className='info-tab col-md-3'>
                <span className='tab-header'>
                    <GiCycling className='tab-header-icon'/>
                    <span className='tab-header-description'>RACE</span>
                </span> 
                <span className='tab-content'>
                    {details.attached_race}
                </span>
            </span>
            <span className='info-tab col-md-3'>
                <span className='tab-header'>
                    <AiFillFlag className='tab-header-icon'/>
                    <span className='tab-header-description'>COUNTRY</span>
                </span> 
                <span className='tab-content'>
                    {details.course_country_iso}
                </span>
            </span>
            <span className='info-tab col-md-3'>
                <span className='tab-header'>
                    <MdClass className='tab-header-icon'/>
                    <span className='tab-header-description'>UCI CATEGORY</span>
                </span> 
                <span className='tab-content'>
                    {details.attached_race_class}
                </span>
            </span>
            <span className='info-tab col-md-3'>
                <span className='tab-header'>
                    <MdDateRange className='tab-header-icon'/>
                    <span className='tab-header-description'>DATE</span>
                </span> 
                <span className='tab-content'>
                    {new Date(details.attached_racedate).toDateString()}
                </span>
            </span>
            <span className='info-tab col-md-3'>
                <span className='tab-header'>
                    <GiPathDistance className='tab-header-icon'/>
                    <span className='tab-header-description'>DISTANCE</span>
                </span> 
                <span className='tab-content'>
                    {details.formatted_distance}km
                </span>
            </span>
            <span className='info-tab col-md-3'>
                <span className='tab-header'>
                    <AiOutlineRise className='tab-header-icon'/>
                    <span className='tab-header-description'>ELEVATION</span>
                </span> 
                <span className='tab-content'>
                    {details.formatted_elevation}m
                </span>
            </span>
            {details.amount_finish_passages>0?<span className='info-tab col-md-3'>
                <span className='tab-header'>
                    <TiArrowLoop className='tab-header-icon'/>
                    <span className='tab-header-description'>FINISH CIRCUIT LAPS</span>
                </span> 
                <span className='tab-content'>
                    {details.amount_finish_passages}
                </span>
            </span>:""}
        </div>
        <div className='idlab-metrics'>
            <h4>IDLab metrics</h4>
            <div className='id-lab-metrics'>
                <span className='info-tab col-md-3'>
                    <span className='tab-header'>
                        <GiCycling className='tab-header-icon'/>
                        <span className='tab-header-description'>BUNCHSPRINT?</span>
                    </span> 
                    <span className='tab-content'>
                        {details.is_bunchsprint?<FcOk fontSize='20pt'/>:<FcCancel fontSize='20pt'/>}
                    </span>
                </span>
                <span className='info-tab col-md-3'>
                    <span className='tab-header'>
                        <ImMeter2 className='tab-header-icon'/>
                        <span className='tab-header-description'>STAGE HARDNESS</span>
                    </span> 
                    <span className='tab-content'>
                        <Gauge value={Math.min(100, Math.round(details.stage_hardness/6000*100))} minMaxLabelStyle={{display: "None"}} minTxt="" maxTxt="" width={150} height={80} label="" />
                    </span>
                </span>
                <span className='info-tab col-md-3'>
                    <span className='tab-header'>
                        <FaTachometerAlt className='tab-header-icon'/>
                        <span className='tab-header-description'>FINISH SPEED PREDICTION</span>
                    </span> 
                    <span className='tab-content'>
                        <div className='speed-indication' style={{height: 60, width: 120, color: details.sprintspeed_prediction>60?"red":details.sprintspeed_prediction>40?"orange":"green"}}>
                            <div class="gauge__body">
                                <div class="gauge__fill" style={{background: details.sprintspeed_prediction>60?"red":details.sprintspeed_prediction>40?"orange":"green", transform: `rotate(${details.sprintspeed_prediction/2/80}turn)`}}></div>
                                <div class="gauge__cover">{Math.round(details.sprintspeed_prediction)}kph</div>
                            </div>
                        </div>
                    </span>
                </span>
                <span className='info-tab col-md-3'>
                    <span className='tab-header'>
                        <FaSignature className='tab-header-icon'/>
                        <span className='tab-header-description'>STRAIGHTNESS</span>
                    </span> 
                    <span className='tab-content'>
                        {straightnessBar('500m_ratio')}
                        {straightnessBar('1000m_ratio')}
                    </span>
                </span>
            </div>
        </div>
        </div>
    )
}