import axiosInstance from './axios';

export const loadCourses = () =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/courses').then((resp)=>{
            resolve(resp.data)
        }).catch(()=>{
            resolve([])
        })
    });
}

export const loadCourseInfo = (id) =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get(`/course_info/${id}`).then((resp)=>{
            resolve(resp.data)
        }).catch(()=>{
            resolve([])
        })
    });
}

export const loadOSMData = (id, every_x_m) => {
    return new Promise((resolve, reject)=>{
        axiosInstance.get(`/osm_info/${id}?every_m=${every_x_m}`).then((resp)=>{
            resolve(resp.data)
        }).catch(()=>{
            resolve([])
        })
    });
}

export const loadCoursePoints = (id) =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get(`/course_points/${id}`).then((resp)=>{
            resolve(resp.data)
        }).catch(()=>{
            resolve([])
        })
    });
}

export const loadCourseTurns = (id) =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get(`/course_turns/${id}`).then((resp)=>{
            resolve(resp.data)
        }).catch(()=>{
            resolve([])
        })
    });
}

export const finishStraightness = (id) =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get(`/finish_score/${id}`).then((resp)=>{
            resolve(resp.data)
        }).catch(()=>{
            resolve([])
        })
    })
}

export const roadFunnels = (id) =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get(`/lane_changes/${id}`).then((resp)=>{
            resolve(resp.data)
        }).catch(()=>{
            resolve([])
        });
    })
}