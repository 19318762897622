import React, { useEffect, useState } from 'react'

import Leaflet, { LatLng, LatLngBounds } from "leaflet";
import {MapContainer, TileLayer, Marker, Polyline, Tooltip} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

import {StartIcon, DefaultIcon, FinishIcon, TurnIcon, FunnelIcon} from '../util/icons'
import ElevationChart from './ElevationChart';

Leaflet.Marker.prototype.options.icon = DefaultIcon;
//const satelliteUrl = "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
const satelliteUrl = "https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiZGVib2NramVsbGUiLCJhIjoiY2plc2Q0ZngxMGlsZjJ3bzJoMmdxbjh1ZyJ9.jW8FTWuPPeWLz8eaYXHYXg"
const osmTileUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"

export default function CourseMapComponent(props) {
    var points = props.coordinates
    var distances = props.distances
    var bounds = props.bounds
    var selectedPoint = props.osmPoint
    var selectedLineSegment = props.selectedLineSegment;
    var showFunnels = props.showFunnels;
    var showSatellite = props.satelliteTiles;

    var [mapInstance, setMapInstance] = useState(null)

    var [lineSegments, setLineSegments] = useState([])
    var [markerCoords, setMarkerCoords] = useState([])
    var [tileLayer, setTileLayer] = useState(<TileLayer key='tile-osm' attribution={'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors '} url={osmTileUrl}/>)

    const setLatLon = (lat, lon) =>{
        if(lat && lon)
            setMarkerCoords([lat, lon])
        else
            setMarkerCoords([])
    }

    useEffect(()=>{
        console.log("Changing the tile layer");
        if(props.satelliteTiles){
            setTileLayer(<TileLayer key='tile-sat' attribution={'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors | satellite by Mapbox'} url={satelliteUrl}/>)
        }
        else{
            setTileLayer(<TileLayer key='tile-osm' attribution={'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors '} url={osmTileUrl}/>)
        }
    }, [showSatellite, props.satelliteTiles])

    useEffect(()=>{
        if(selectedLineSegment.length>0 ){
            var minLat = selectedLineSegment[0][0];
            var maxLat = selectedLineSegment[0][0];
            var minLon = selectedLineSegment[0][1];
            var maxLon= selectedLineSegment[0][1];

            for(var i = 1; i<selectedLineSegment.length;i++){
                var lat = selectedLineSegment[i][0];
                var lon = selectedLineSegment[i][1];
                if(lat<minLat)
                    minLat = lat
                if(lat>maxLat)
                    maxLat = lat
                if(lon<minLon)
                    minLon = lon
                if(lon>maxLon)
                    maxLon = lon
            }
            mapInstance.fitBounds(new LatLngBounds(new LatLng(minLat, minLon), new LatLng(maxLat, maxLon)), {padding: [50,50]});
        }
        else if(mapInstance){
            mapInstance.fitBounds(bounds)
        }
    },[selectedLineSegment, bounds, mapInstance])

    useEffect(()=>{
        console.log("Selected OSM point changed");
        setLatLon(selectedPoint[0], selectedPoint[1])
    },[selectedPoint])


    useEffect(()=>{
        if(points.length>=2 && distances.length>=2){
            console.log("Points changed")
            var segments = []
            var i=0;
            var j=i+1;
            var segment_trend = (points[j][2]-points[i][2])/Math.abs(points[j][2]-points[i][2])
            var last_pt_index=j-1;
            var trend_points=[[points[i][0], points[i][1]]]
            while(j<points.length){
                var elevation_diff = points[j][2]-points[last_pt_index][2];
                var distance_diff = distances[j]-distances[last_pt_index]
                //console.log(elevation_diff,',',distance_diff);
                if(distance_diff>=5 && Math.abs(elevation_diff)>=2)
                {
                    last_pt_index=j;
                    var trend = elevation_diff/Math.abs(elevation_diff);
                    //console.log(i,",",j,",",last_pt_index);
                    if(trend!==segment_trend)
                    {
                        //console.log(trend,',',segment_trend);
                        var segment_elevation_diff = points[j][2]-points[i][2]
                        var segment_distance_diff = distances[j]-distances[i]
                        var grade = segment_elevation_diff/segment_distance_diff;
                        var segment_trend_points = trend_points;
                        segment_trend_points.push([points[j][0], points[j][1]])
                        var color = ""
                        var factor = Math.min(1,Math.abs(grade)*14)
                        if(segment_trend===1){
                            color = `rgb(${150*factor},212,${150*factor}`
                        }
                        else{
                            color = `rgb(${factor*255},0,0)`
                        }
                        segments.push(
                            <Polyline 
                                key={`segment-${j}`}
                                positions={segment_trend_points} 
                                color={color}>                            
                            </Polyline>)
                        i=last_pt_index;
                        if(i+1<points.length){
                            segment_trend = trend
                            last_pt_index=j;
                            trend_points=[]
                        }
                        else{
                            trend_points=[];
                        }
                    }
                }
                trend_points.push([points[j][0], points[j][1]])
                j++;
            }
            if(trend_points.length>=2){
                segment_elevation_diff = points[points.length-1][2]-points[i][2]
                segment_distance_diff = distances[points.length-1]-distances[i]
                segment_trend_points = trend_points;
                if(trend_points[trend_points.length-1]!==points[points.length-1])
                    segment_trend_points.push([points[points.length-1][0], points[points.length-1][1]])
                grade = segment_elevation_diff/segment_distance_diff;
                color = ""
                factor = 1-Math.min(1,Math.abs(grade)*10)
                if(segment_trend===1){
                    color = `rgb(${150*factor},212,${150*factor}`
                }
                else{
                    color = `rgb(${factor*255},0,0)`
                }
                segments.push(
                    <Polyline 
                        key={`segment-${j}`}
                        positions={segment_trend_points} 
                        color={color}>                            
                    </Polyline>)
            }
            setLineSegments(segments);
        }
    }, [points, distances])

    useEffect(()=>{
        if(mapInstance)
            mapInstance.fitBounds(bounds)
    }, [mapInstance, bounds])

    const createFunnelMarkers = () => {
        return props.funnels.map((funnel)=>{
            return (
                <Marker key={`marker-funnel-${funnel.point_index}`} icon={FunnelIcon} position={[points[funnel.point_index][0], points[funnel.point_index][1]]}>
                    <Tooltip>{`${funnel.change_type} : from ${funnel.lanes_from} to ${funnel.lanes_to} lanes`}</Tooltip>
                </Marker> 
            )
        })
    }
    
    return (
        <div className='mapcontainer'>
            {points.length===0?<div className='bicycle-loader'><img alt='map is loading' src={process.env.PUBLIC_URL+'/icons/bikeloader.gif'}/>Loading ...</div>:""}
            <MapContainer  
                key={`map-${satelliteUrl?'sat':'osm'}`}
                bounds={bounds}
                style={{height: '60vh'}}
                whenCreated={ instance => { 
                    setMapInstance(instance)
                }}
            >
                {tileLayer}
                {points.length>0?<Marker key={`marker-start`} icon={StartIcon} position={[points[0][0], points[0][1]]}/>:""}
                {points.length>0?<Marker key={`marker-end`} icon={FinishIcon} position={[points[points.length-1][0], points[points.length-1][1]]}/>:""}
                {lineSegments.map((segment) => {
                    return (segment)
                })}
                {markerCoords.length>0?<Marker key={`marker-location`} icon={DefaultIcon} position={[markerCoords[0], markerCoords[1]]}/>:""}
                {selectedLineSegment.length>0?<Polyline key='penalty-highlight' positions={selectedLineSegment} weight={7} color={"#f06192"}></Polyline>:""}
                {props.selectedTurn.length>0?<Marker key={`marker-turn`} icon={TurnIcon} position={[props.selectedTurn[0], props.selectedTurn[1]]}/>:""}
                {showFunnels?createFunnelMarkers():""}
            </MapContainer>
                <ElevationChart data={points} setLatLon={setLatLon} selectedPoint={selectedPoint}/>
        </div>
    )
  }