import { useParams } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import {loadCourseInfo, loadCoursePoints, finishStraightness, roadFunnels} from '../api/course'
import {formatCourse} from '../util/formatting'

import MyCourseMapComponent from '../subcomponents/map_container'
import CourseDetails from '../subcomponents/course_details'
import CourseTurns from '../subcomponents/course_turns'
import OSMTable from "../subcomponents/osm_table";
import PenaltySegment from "../subcomponents/PenaltySegments";
import Switch from "../elements/switch";
import {BsFillInfoSquareFill} from 'react-icons/bs'

export default function Course(){
    let {id} = useParams()
    const [course, setCourse] = useState(null)
    const [finishlineStraightness, setFinishlineStraightness] = useState(null)
    const [points, setPoints] = useState([])
    const [bounds, setBounds] = useState([[-90,-180],   [90,180]])
    const [turnLatLon, setTurnLatLon] = useState([])
    const [selectedOSMPoint, setSelectedOSMPoint] = useState([])
    const [selectedLineSegment, setSelectedLineSegment] = useState([])
    const [distances, setDistances] = useState([])
    const [funnels, setFunnels] = useState([])
    const showFunnels = false
    const [showSatelliteTiles, setShowSatelliteTiles] = useState(false)

    useEffect(()=>{
        loadCourseInfo(id).then((data)=>{
            setCourse(formatCourse(data))
            setBounds([[data['course_bbox'][0], data['course_bbox'][1]],[data['course_bbox'][2], data['course_bbox'][3]]])
        })
    },[id])

    useEffect(()=>{
        finishStraightness(id).then((data)=>{
            setFinishlineStraightness(data)
        })
    },[id])

    useEffect(()=>{
        roadFunnels(id).then((data)=>{
            setFunnels(data)
        });
    }, [id])

    useEffect(()=>{
        //Loading the course points
        //console.log("Reloading course points");
        loadCoursePoints(id).then((data)=>{
            var coordinates = []
            var localDistances = []
            for(var point of data){
                coordinates.push([point.latitude, point.longitude, point.elevation, point.upcoming_grade])
                localDistances.push(point.distance_done)
            }
            setPoints(coordinates)
            setDistances(localDistances);
        })
    }, [course, id])

    const setTurn = (idx)=>{
        if(idx && points.length>0){
            var lat = points[idx][0],
            lon = points[idx][1]
            setTurnLatLon([lat, lon])
        }
        else{
            setTurnLatLon([])
        }
    }

    const setOSMPoint = (index) =>{
        if(points[index]){
            setSelectedOSMPoint([points[index][0], points[index][1]])
        }
    }

    const setLineSegment = (index) =>{
        if(points.length>0 && "penalty_segments" in course && course["penalty_segments"][index]){
            var start = course["penalty_segments"][index]["segment_start_index"]
            var end = course["penalty_segments"][index]["segment_end_index"]
            var segmentPoints = []
            for(var i=start; i<=end; i++){
                segmentPoints.push([points[i][0], points[i][1]])
            }
            setSelectedLineSegment(segmentPoints)
        }
    }

    const unsetOSMPoint = () =>{
        setSelectedOSMPoint([])
    }

    const unsetLineSegment = () =>{
        setSelectedLineSegment([])
    }

    if(course){
        return (
            <div className='courseViewer'>
                <h1>{course.attached_race}</h1>
                <div className='row'>
                    <div className='col col-md-6'>
                        <h4 style={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between'}}>
                            <span style={{display: 'flex'}}>Course map</span>
                            <span className='tileToggle' style={{display: 'flex'}}>
                                <i className="fa-solid fa-map-location-dot" style={{marginRight: '5px'}}></i>
                                <Switch id='switch-sat' key='switch-sat' isOn={showSatelliteTiles} handleToggle={()=>{console.log("Toggling sat");setShowSatelliteTiles(!showSatelliteTiles)}} onColor='green'/>
                                <i className="fa-solid fa-satellite-dish" style={{marginLeft: '5px'}}></i>
                                <span style={{display: 'flex', fontSize: 'small', marginLeft: '5px', cursor: 'pointer', color: 'gray'}} data-toggle="tooltip" title="Switch between OSM and Satellite tiles">
                                    <BsFillInfoSquareFill/>
                                </span>
                            </span>
                        </h4>
                        <MyCourseMapComponent bounds={bounds} coordinates={points} distances={distances} osmPoint={selectedOSMPoint} selectedTurn={turnLatLon} selectedLineSegment={selectedLineSegment} satelliteTiles={showSatelliteTiles} funnels={funnels} showFunnels={showFunnels}/>                   
                    </div>
                    <div className='col col-md-6'>
                        <Tabs onSelect={(e)=>{console.log("Change tab");unsetLineSegment()}}>
                            <TabList>
                                <Tab>
                                    Course info
                                </Tab>
                                <Tab>
                                    Course turns
                                </Tab>
                                <Tab>
                                    Course OSM data
                                </Tab>
                                <Tab>
                                    Course segments
                                </Tab>
                            </TabList>
                            <TabPanel>
                                <CourseDetails details={course} finishlineStraightness={finishlineStraightness}/>
                            </TabPanel>
                            <TabPanel>
                                <CourseTurns details={course} id={id} points={points} setTurnLatLon={setTurn} />
                            </TabPanel>
                            <TabPanel>
                                <OSMTable id={id} setOSMPoint={setOSMPoint} unsetOSMPoint={unsetOSMPoint}/>
                            </TabPanel>
                            <TabPanel>
                                <PenaltySegment penaltySegments={"penalty_segments" in course?course["penalty_segments"]:[]}
                                                distances={distances}
                                                setLineSegment={setLineSegment}
                                                unsetLineSegment={unsetLineSegment}
                                />
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
    else{
        return (
            <div className='courseViewer'>
                <h1>Details of course {id}</h1>
                Loading...
            </div>
        )
    }
    
}