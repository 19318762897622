import React from 'react';
import './Switch.css';

const Switch = (props) => {
  var isOn = props.isOn;
  var handleToggle = props.handleToggle;
  var onColor = props.onColor;
  var id = props.id;
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`switch-${id}`}
        type="checkbox"
      />
      <label
        style={{ background: isOn && onColor }}
        className="react-switch-label"
        htmlFor={`switch-${id}`}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default Switch;