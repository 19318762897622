import React, { useState, useEffect } from 'react'
import MaterialTable, { MTableBodyRow } from "material-table";

import {loadOSMData} from '../api/course'

export default function OSMTable(props){
    var id = props.id
    var [osmData, setOsmData] = useState([])
    const [hoveringOver, setHoveringOver] = useState('');

    const handleRowHover = (event, propsData) => {
        event.preventDefault()
        setHoveringOver(osmData[propsData.data.tableData.id].point_index);
        props.setOSMPoint(hoveringOver)
        //console.log("Hover in!");

    };
    const handleRowHoverLeave = (event, propsData) => {
        event.preventDefault()
        setHoveringOver('');
        props.unsetOSMPoint()
        //console.log("Hover out!")
    }

    useEffect(()=>{
        loadOSMData(id, 50).then((response)=>{
            var osm_data = []
            for(var osm_entry of response){
                if('cummulated_distance' in osm_entry){
                    osm_entry['remaining_distance']=Math.round(response[response.length-1]['cummulated_distance']-osm_entry['cummulated_distance'])
                    osm_data = [...osm_data, osm_entry]
                }
            }
            setOsmData(osm_data)
        })
    },[id])

    let columns = [
            { title: 'Remaining distance (m)', field: 'remaining_distance', defaultSort: 'desc'},
            { title: 'Number of lanes', field: 'lanes'},
            { title: 'Road surface', field: 'road_surface'},
            { title: 'Street name', field: 'street_name'},
            { title: 'Way type', field: 'way_type'},
            { title: 'n_changes', field: 'differences', hidden: true},
            { title: 'point_index', field: 'point_index', hidden: true}
    ];

    return (
        <div className='osmTable'>
            <MaterialTable
                title='OSM Data last kms'
                columns={columns}
                data={osmData}
                options={{
                    filtering: true, 
                    paging: true, 
                    pageSize:100,
                    pageSizeOptions: [10,20,50,100], 
                    sorting: true,
                    rowStyle: rowData => {
                        if(rowData.differences > 0) {
                          return {backgroundColor: 'orange'};
                        }
                        return {};
                    }
                }} 
                components={{
                    Row: props => {
                      return (
                        <MTableBodyRow 
                          {...props} 
                          onMouseEnter={e => {e.preventDefault();handleRowHover(e, props)}} 
                          onMouseLeave={e => {e.preventDefault();handleRowHoverLeave(e, props)}}
                        />
                      )
                    }
                  }}
            />
        </div>
        
    )
}