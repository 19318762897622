import React, { useEffect, useState, useCallback} from "react"
import { Chart, Scatter } from "react-chartjs-2";
import 'chartjs-plugin-zoom';
import {findClosestIndex, processPointsToElevationProfile} from '../util/geo_processing'
import {customTooltips} from '../util/formatting'
import Button from '@material-ui/core/Button';

// Extra plugin : draw a vertical hairline when user hovers over the elevation
// chart.
Chart.plugins.register({
  afterDatasetsDraw: function(chart) {
     if (chart.tooltip._active && chart.tooltip._active.length) {
        var activePoint = chart.tooltip._active[0]
        //console.log(activePoint);
        var ctx = chart.ctx;
        var y_axis = chart.scales['y-axis-1']
        var x = activePoint.tooltipPosition().x
        var topY = y_axis.top
        var bottomY = y_axis.bottom;
        // draw line
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#07C';
        ctx.stroke();
        ctx.restore();
     }
  }
});

//Component that takes care of drawing the elevation profile
export default function ElevationChart(props){
    //Convert [lat, lon, elevation] to an elevation_profile friendly data structure
    // --> [{x: cum_distance, y: elevation_meter}, ...], [[lat, lon], ...]
    var selectedPoint = props.selectedPoint
    var data = processPointsToElevationProfile(props.data);
    var elevation_profile = data[0]
    var points = data[1]
    // Calculates the max cummulative distance (so we can set our X-axis)
    var max_distance = (elevation_profile && elevation_profile.length>0)?elevation_profile[elevation_profile.length-1].x:0

    // Will hold the reference to the chart.js linechart instance
    var [lineChartInst, setLineChartInst] = useState(null);
    var [xMax, setxMax] = useState(max_distance);
    var [xMin, setxMin] = useState(0);

    useEffect(()=>{
      if(lineChartInst){
        lineChartInst.chartInstance.resetZoom();
        lineChartInst.chartInstance.chart.draw();    
      }
    }, [xMax, xMin])

    useEffect(()=>{
      if(max_distance>0)
        setxMax(max_distance)
    }, [max_distance]);

    // The options for drawing our chart (area)
    const options = {
      legend: {
        display: false //We don't need a legend
      },
      onHover: e => {
        // When a user hovers over the chart area this function will lookup the elevation at the x location of the cursor
        // --> we will get the item index and look up its coordinates in the points array, so we can set the location
        //      marker on the map (managed by using the setLatLon prop).
        const item = lineChartInst.chartInstance.chart.getElementsAtEventForMode(e, 'index', { intersect: false }, false)
        if(item.length) {
          const lat = points[item[0]._index][0],
                lon = points[item[0]._index][1]
          props.setLatLon(lat, lon)
        }
      },
      showLine: true, //As we're using a scatter plot, we explicitly say we need a line between the "dots"
      responsive: true, // Dyanmically resize the container
      maintainAspectRatio: false,
      pointBackgroundColor: "rgb(255,255,255)", 
      layout: {
        padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function(value, index, values) { // We transform our y axis labels and add "meter" after every label
                return value+'m';
              }
            }
          },
        ],
        xAxes: [
          {
            ticks: {
              beginAtZero: false,
              callback: function(value, index, values){
                return Math.round(value/1000)+"km"  // We transform our x axis labels, we convert it from meters to km and add the unit after every tick
              },
              stepSize: 5000
            }
          }
        ]
      },
      tooltips: {
        // We want a tooltip whenever the user hovers over the chart area (it will lookup the element at index x)
        mode: 'index',
        intersect: false, // Here we explicitly mention that the user should not necessarily intersect with the line whilst hovering
        enabled: false,
        custom: customTooltips
      },
      plugins:{
        zoom: {
          zoom: {
            enabled: true,
            drag: true,
            mode: 'x',
            overScaleMode: 'x',
          },
          pan: {
            enabled: false,
            mode: 'x',
          }
        }
      } 
    }

    const handleZoomReset = () => {
      setxMin(0);
      setxMax(max_distance);
      lineChartInst.chartInstance.resetZoom();
    }

    const moveTooltip = useCallback((point)=>{
      var index = findClosestIndex(point, points)
      var requestedElem = lineChartInst.chartInstance.chart.getDatasetMeta(0).data[index];
      
      lineChartInst.chartInstance.chart.tooltip._active = [requestedElem];
      lineChartInst.chartInstance.chart.tooltip.update(true);
      lineChartInst.chartInstance.chart.draw();    
    }, [points, lineChartInst])

    const removeTooltip = useCallback(()=>{
      if(lineChartInst){
        lineChartInst.chartInstance.chart.tooltip._active = [];
        lineChartInst.chartInstance.chart.tooltip.update(true);
        lineChartInst.chartInstance.chart.draw();
      }
    }, [lineChartInst])

    useEffect(()=>{
      if(selectedPoint && selectedPoint.length>0 && lineChartInst)
        moveTooltip(selectedPoint)
      else
        removeTooltip()
    },[selectedPoint, lineChartInst, moveTooltip, removeTooltip])
        
    return (
      <div className='charts' height='300px' onMouseLeave={(e)=>{
          console.log("Mouse leave");
          props.setLatLon(null, null);
          document.getElementById('chartjs-tooltip').style.opacity = 0  
        }}>
        {props.data.length>0 && <Scatter key='elevation-profile' data={{  datasets: [
                                  {
                                    label: 'Elevation profile',
                                    data: elevation_profile,
                                    backgroundColor: 'rgba(255, 255, 255, 0)',
                                    borderColor: 'rgba(0,0,0,1)',
                                    pointRadius: 0.01,
                                    showLine: true
                                  },
                                ],
                              }} options={options}
                              ref={reference => setLineChartInst(reference)}/>
                              }
                                                            <div className="form-group float-right">
                                <Button onClick={handleZoomReset} color="primary" type="button" variant="outlined">
                                    Reset Zoom
                                </Button>
                                </div>
      </div>
      )
}