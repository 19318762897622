import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';

import './App.scss';

//Static parts of the page
import Header from './static/header'
import Footer from './static/footer'

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <Header/>
      <App/>
      <Footer/>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
