import { Route } from "react-router-dom";

import Home from './components/home'
import Course from './components/course'

function App() {
  return (
    <div className="App">
        <Route exact path={`/`} component={Home}/>
        <Route exact path={`/course/:id`} component={Course}/>
    </div>
  );
}

export default App;
