import React from 'react'
import CoursesTable from '../subcomponents/courses_table'

export default function Home(){
    return (
        <div className='course-browser'>
            <h1>Course browser</h1>
            <CoursesTable/>
        </div>
    )
}